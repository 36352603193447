var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable no-cond-assign */
import { toast } from "react-toastify";
import * as Constant from "./Constants";
import { getItem } from "./LocalStorageUtil";
import { PERMISSION_DATA_KEY, SHIPPING_ADDRESS, TOKEN_KEY, USER_DATA_KEY, } from "./StorageKeys";
import moment from "moment";
import { API_PERMISSION_NAME, ROLE_TYPE, SUB_URL } from "../config";
import { matchPath } from "react-router";
import { MANAGE_TAB_NAME, MANAGE_TABS, } from "../components/header-bar/parts/SidebarNav";
export var commonFn = function () { };
export var timeoutFn = function (ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
};
export var notificationSimple = function (message, level, position) {
    var positionNotification = position ? position : "top-right";
    var configDefault = {
        position: positionNotification,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    switch (level) {
        case Constant.NOTIFICATION_SUCCESS:
            return toast.success(message, configDefault);
        case Constant.NOTIFICATION_WARN:
            return toast.warn(message, configDefault);
        case Constant.NOTIFICATION_INFO:
            return toast.info(message, configDefault);
        case Constant.NOTIFICATION_ERROR:
            return toast.error(message, configDefault);
        case Constant.NOTIFICATION_DARK:
            return toast.dark(message, configDefault);
        default:
            return toast(message, configDefault);
    }
};
export var decimalToHexString = function (chain) {
    chain = Number(chain);
    if (chain < 0) {
        chain = 0xffffffff + chain + 1;
    }
    return "0x".concat(chain.toString(16).toUpperCase());
};
export var customSplice = function (array, val) {
    for (var i = array.length; i--;) {
        if (array[i] === val) {
            array.splice(i, 1);
        }
    }
};
export var copyTextToClipboard = function (text) { return __awaiter(void 0, void 0, void 0, function () {
    var textArea;
    return __generator(this, function (_a) {
        // if ('clipboard' in navigator && window.isSecureContext) {
        //   console.log("navigator.clipboard.writeText")
        //   return await navigator.clipboard.writeText(text);
        // } else {
        console.log("document.execCommand");
        textArea = document.createElement("textarea");
        textArea.value = text;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return [2 /*return*/, new Promise(function (res, rej) {
                // here the magic happens
                document.execCommand("copy") ? res() : rej();
                textArea.remove();
            })];
    });
}); };
export var replaceUrlNavigate = function (url) {
    if (SUB_URL && url && url.includes(SUB_URL)) {
        var pathnames = url.split(SUB_URL);
        if (pathnames && pathnames.length) {
            return pathnames[pathnames.length - 1];
        }
    }
    return url;
};
var byPassUrl = function (permissionData, url) {
    var manageBrandTab = MANAGE_TABS.find(function (tab) { return tab.name === MANAGE_TAB_NAME.manage_brand; });
    var manageBrandTabUrl = "";
    if (manageBrandTab && manageBrandTab.url) {
        if (SUB_URL && manageBrandTab.url && manageBrandTab.url.includes(SUB_URL)) {
            manageBrandTabUrl = manageBrandTab.url.split(SUB_URL)[1];
        }
        else {
            manageBrandTabUrl = manageBrandTab.url;
        }
    }
    if (manageBrandTabUrl === url &&
        permissionData &&
        permissionData.roleType[ROLE_TYPE.brandRole] &&
        permissionData.roleType[ROLE_TYPE.brandRole].length > 0) {
        return true;
    }
    return false;
};
export var filterTabsByPermission = function (tabs) {
    var filteredTabs = tabs.filter(function (tab) {
        return checkUIPermission(tab.url, true);
    });
    return filteredTabs;
};
export var checkUIPermission = function (pathname, isUI) {
    try {
        if (SUB_URL && pathname && pathname.includes(SUB_URL)) {
            pathname = pathname.split(SUB_URL)[1];
        }
        var permissionData = getCurrentUser();
        var affiliateStatus = getAffiliateStatus();
        if (affiliateStatus !== 1 &&
            (pathname === "/my/link" || pathname === "/my/tracking-conversion")) {
            return false;
        }
        if (byPassUrl(permissionData, pathname)) {
            return true;
        }
        if (permissionData && permissionData.roleType) {
            for (var _i = 0, _a = Object.keys(permissionData.roleType); _i < _a.length; _i++) {
                var roleType = _a[_i];
                if (isUI &&
                    (!pathname || pathname.length === 0) &&
                    ROLE_TYPE.brandRole.toString() === roleType.toString()) {
                    return true; // This Brand Admin Tab
                }
                for (var _b = 0, _c = permissionData.roleType[roleType]; _b < _c.length; _b++) {
                    var role = _c[_b];
                    if (ROLE_TYPE.brandRole.toString() === roleType.toString()) {
                        if (permissionData.roleByBrand) {
                            for (var _d = 0, _e = Object.keys(permissionData.roleByBrand); _d < _e.length; _d++) {
                                var roleByBrand = _e[_d];
                                if (permissionData.roleByBrand[roleByBrand].includes(role)) {
                                    if (permissionData.uiRoles && permissionData.uiRoles[role]) {
                                        for (var _f = 0, _g = permissionData.uiRoles[role]; _f < _g.length; _f++) {
                                            var pattern = _g[_f];
                                            if (isUI) {
                                                var matched = matchPath({ path: pattern, exact: true }, pathname);
                                                if (matched) {
                                                    return true;
                                                }
                                            }
                                            else {
                                                if (permissionData.apiRoles &&
                                                    permissionData.apiRoles[role] &&
                                                    permissionData.apiRoles[role].includes(pathname)) {
                                                    return true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else {
                        if (permissionData.uiRoles && permissionData.uiRoles[role]) {
                            for (var _h = 0, _j = permissionData.uiRoles[role]; _h < _j.length; _h++) {
                                var pattern = _j[_h];
                                if (isUI) {
                                    var matched = matchPath({ path: pattern, exact: true }, pathname);
                                    if (matched) {
                                        return true;
                                    }
                                }
                                else {
                                    if (permissionData.apiRoles &&
                                        permissionData.apiRoles[role] &&
                                        permissionData.apiRoles[role].includes(pathname)) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    catch (error) {
        console.log(error);
    }
    return false;
};
var getAffiliateStatus = function () {
    var user = getItem(USER_DATA_KEY);
    var parseUser = JSON.parse(user);
    return parseUser.affiliateStatus;
};
export var checkAPIPermission = function (permission) {
    var matched = checkUIPermission(permission, false);
    return matched;
};
export var getCurrentUser = function () {
    var currentUser = getItem(PERMISSION_DATA_KEY);
    return currentUser ? JSON.parse(currentUser) : null;
};
export var getShippingAddress = function () {
    var shippingAddress = getItem(SHIPPING_ADDRESS);
    return shippingAddress ? JSON.parse(shippingAddress) : null;
};
export var getRoleUser = function () {
    var currentUser = getCurrentUser();
    if (currentUser && currentUser.roles) {
        return currentUser.roles;
    }
    return null;
};
export var checkRoleUser = function (role) {
    var currentUser = getCurrentUser();
    if (currentUser && currentUser.uiRoles) {
        var foundRole = Object.keys(currentUser.uiRoles).find(function (roleUser) {
            return roleUser === role;
        });
        return foundRole ? true : false;
    }
    return false;
};
export var checkRolePermissionUser = function (role, permission) {
    var currentUser = getCurrentUser();
    if (currentUser && currentUser.roles) {
        var foundRole = Object.keys(currentUser.roles).find(function (roleUser) { return roleUser === role; });
        if (foundRole &&
            currentUser.roles[foundRole] &&
            currentUser.roles[foundRole].length) {
            var foundPermission = (currentUser.roles[foundRole] || []).find(function (rolePermission) { return rolePermission == permission; });
            return foundPermission ? true : false;
        }
    }
    return false;
};
export var checkPermissionUser = function (permission) {
    var currentUser = getCurrentUser();
    if (currentUser && currentUser.roles) {
        var foundRole = Object.keys(currentUser.roles).find(function (role) {
            var foundPermission = (currentUser.roles[role] || []).find(function (rolePermission) { return rolePermission == permission; });
            return foundPermission ? true : false;
        });
        return foundRole ? true : false;
    }
    return false;
};
export var dateConverter = function (startDate, timeEnd, unitOfTime) {
    if (startDate && timeEnd) {
        var newStartDate = new Date(startDate);
        var newEndDate = new Date(timeEnd);
        var number = moment(newEndDate).diff(newStartDate, unitOfTime);
        if (number && number > 0) {
            return number + " " + unitOfTime;
        }
        else {
            return "Unlimited";
        }
    }
    return null;
};
export var socialNetworkLogo = [
    {
        name: "facebook",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/facebook_logo.png",
    },
    {
        name: "discord",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/discord_logo.png",
    },
    {
        name: "instagram",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/instagram_logo.png",
    },
    {
        name: "linkedin",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/linkedin_logo.png",
    },
    {
        name: "reddit",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/reddit_logo.png",
    },
    {
        name: "substack",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/substack_logo.png",
    },
    {
        name: "telegram",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/telegram_logo.png",
    },
    {
        name: "tiktok",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/tiktok_logo.png",
    },
    {
        name: "twitter",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/twitter_logo.png",
    },
    {
        name: "youtube",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/youtube_logo.png",
    },
    {
        name: "binance",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/union_logo.png",
    },
];
export var socialPOVLogo = [
    {
        name: "facebook",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/facebook_w_logo.png",
    },
    {
        name: "binance",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/binance_w_logo.png",
    },
    {
        name: "discord",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/discord_logo.png",
    },
    {
        name: "instagram",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/instagram_logo.png",
    },
    {
        name: "linkedin",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/linkedin_logo.png",
    },
    {
        name: "reddit",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/reddit_logo.png",
    },
    {
        name: "substack",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/substack_logo.png",
    },
    {
        name: "telegram",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/telegram_logo.png",
    },
    {
        name: "tiktok",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/tiktok_logo.png",
    },
    {
        name: "twitter",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/twitter_w_logo.png",
    },
    {
        name: "youtube",
        socialNetworkLogoUrl: "https://static-openlivenft.genexwifi.com/resource/images/social/youtube_logo.png",
    },
];
export var filteredBrandByPermission = function (brands, brandIdField) {
    var invitableBrandsChecking = [];
    try {
        var permissionData = getCurrentUser();
        if (brands && permissionData) {
            for (var _i = 0, brands_1 = brands; _i < brands_1.length; _i++) {
                var brand = brands_1[_i];
                var brandInfo = brand[brandIdField];
                if (brandInfo &&
                    permissionData.roleByBrand &&
                    permissionData.apiRoles &&
                    permissionData.roleByBrand[brandInfo]) {
                    for (var _a = 0, _b = permissionData.roleByBrand[brandInfo]; _a < _b.length; _a++) {
                        var roleName = _b[_a];
                        if (permissionData.apiRoles[roleName] &&
                            permissionData.apiRoles[roleName].includes(API_PERMISSION_NAME.invite_staff)) {
                            invitableBrandsChecking.push(brand);
                        }
                    }
                }
            }
        }
    }
    catch (error) {
        console.log(error);
    }
    return invitableBrandsChecking;
};
var COST = "COST";
var PERCENTAGE = "PERCENTAGE";
var DATE_TIME = "DATE_TIME";
var TEXT = "TEXT";
export var formatPercentage = function (amount, numberOfDecimals) {
    if (numberOfDecimals === void 0) { numberOfDecimals = 2; }
    return parseFloat(amount).toFixed(numberOfDecimals) + "%";
};
export var formatCurrency = function (amount, numberOfDecimals) {
    if (numberOfDecimals === void 0) { numberOfDecimals = 2; }
    return ("￥" +
        amount.toFixed(numberOfDecimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
};
export var formatNumber = function (num, numberOfDecimals) {
    if (numberOfDecimals === void 0) { numberOfDecimals = 0; }
    if (num === 0) {
        return "0";
    }
    else {
        var formatNumber_1 = num
            .toFixed(numberOfDecimals)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            .split(".");
        if (formatNumber_1[1]) {
            formatNumber_1[1] = formatNumber_1[1].replaceAll(",", "");
        }
        if (!formatNumber_1[1] || !formatNumber_1[1].match(/[^0]/g)) {
            return formatNumber_1[0];
        }
        return "".concat(formatNumber_1[0], ".").concat(formatNumber_1[1]);
    }
};
export var formatValueByType = function (value, dataType, decimalPrecision) {
    if (value === null || value === undefined) {
        return "";
    }
    if (isNaN(value)) {
        return value;
    }
    var formattedValue = "";
    if (dataType === COST) {
        var val = Number(value);
        formattedValue = formatCurrency(val, decimalPrecision);
    }
    else if (dataType === PERCENTAGE) {
        var val = Number(value);
        formattedValue = formatPercentage(val, decimalPrecision);
    }
    else if (dataType === DATE_TIME) {
        formattedValue = moment(value).format("YYYY-MM-DD");
    }
    else if (dataType === TEXT) {
        formattedValue = value;
    }
    else {
        formattedValue = formatNumber(Number(value), decimalPrecision);
    }
    return formattedValue;
};
export var getTextContentOnly = function (htmlString) {
    var doc = new DOMParser().parseFromString(htmlString, "text/html");
    var walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null);
    var texts = [];
    var node;
    while ((node = walker.nextNode())) {
        texts.push(node.nodeValue);
    }
    return texts.join(" ");
};
export var checkEndBiggerThanStartDate = function (startDate, endDate) {
    return new Date(startDate).getTime() < new Date(endDate).getTime();
};
export var navigateToUrl = function (url) {
    // only navigate url if different from current url
    // build full url with protocol, hostname, port and sub url
    var fullUrl = window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        SUB_URL +
        url;
    console.log('fullUrl', fullUrl);
    console.log('window.location.href', window.location.href);
    if (window.location.href !== fullUrl) {
        window.location.href = fullUrl;
    }
};
export var openNewTabWithUrl = function (url) {
    window.open(window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        SUB_URL +
        url, "_blank");
};
export var contractAddressRender = function (contractAddress, numberOfFirstChars, numberOfLastChars) {
    var firstChars = contractAddress.substring(0, numberOfFirstChars);
    var lastChars = contractAddress.substring(contractAddress.length - numberOfLastChars);
    return firstChars + "..." + lastChars;
};
export var renderDate = function (date, format) {
    return process.env.TIME_ZONE
        ? moment(date).subtract(7, "h").format(format)
        : moment(date).format(format);
};
export var getFileType = function (file) {
    if (file && !!(file === null || file === void 0 ? void 0 : file.type)) {
        if (file.type.match("image.*")) {
            return "image";
        }
        else if (file.type.match("video.*")) {
            return "video";
        }
        else if (file.type.match("audio.*")) {
            return "audio";
        }
        else if (file.type.match("application/pdf")) {
            return "pdf";
        }
        else if (file.type.match("application.*")) {
            return "doc";
        }
    }
    return "other";
};
export var randomNumber = function (start, end) {
    if (start === void 0) { start = 100000; }
    if (end === void 0) { end = 999999; }
    return Math.floor(Math.random() * end) + start;
};
export var bytesToMB = function (bytes) { return (bytes / (1024 * 1024)).toFixed(2); };
export var isAuthenticated = function () {
    var token = getItem(TOKEN_KEY);
    return token ? true : false;
};
export var isManipulatable = function (el) {
    return typeof el !== "undefined" && el !== null;
};
export var obscureEmail = function (email) {
    var _a = email.split("@"), localPart = _a[0], domain = _a[1];
    var visibleLength = 3;
    if (localPart.length <= visibleLength) {
        return email;
    }
    var obscuredPart = localPart.slice(1, -2).replace(/./g, "*");
    var obscuredEmail = "".concat(localPart[0]).concat(obscuredPart).concat(localPart.slice(-2), "@").concat(domain);
    return obscuredEmail;
};
export var formatTimeFromSecond = function (seconds) {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
    var formattedMinutes = String(minutes).padStart(2, "0");
    var formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return "".concat(formattedMinutes, ":").concat(formattedSeconds);
};
export var capitalizeFirstLetter = function (text) {
    if (!text)
        return text;
    // trim and lowercase the text
    text = text.trim().toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
export var formatAbbreviateNumber = function (num, fixed) {
    if (fixed === void 0) { fixed = 1; }
    if (num === 0)
        return "0";
    var units = ["", "K", "M", "G", "T", "P", "E"];
    var absNum = Math.abs(num);
    var sign = num < 0 ? "-" : "";
    var unitIndex = 0;
    var abbreviated = absNum;
    while (abbreviated >= 1000 && unitIndex < units.length - 1) {
        abbreviated /= 1000;
        unitIndex++;
    }
    return "".concat(sign).concat(abbreviated.toFixed(fixed)).concat(units[unitIndex]);
};
