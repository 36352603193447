var config = {
    version: '1.2.8.2',
    api: {
        host: process.env.API_URL,
    },
    global: {
        globalUpdateInterval: process.env.GLOBAL_UPDATE_INTERVAL || 30000,
        subUrl: process.env.SUB_URL,
        cdnUrl: process.env.CDN_URL || "https://static-openlivenft.genexwifi.com/",
        downloadMetamaskUrl: process.env.DOWNLOAD_METAMASK_URL || "https://metamask.app.link/dapp/",
        obrandingURL: process.env.OBRANDING_MEMBERSHIP_URL || "collection/233",
        googleRecaptcharKey: process.env.GOOGLE_RECAPTCHA_KEY || "6LdL5xAqAAAAALNj7kAg9aZ6_VZmTXU8eckElQHn"
    },
    roleType: {
        staffRole: process.env.STAFF_ROLE || 0,
        brandRole: process.env.BRAND_ROLE || 1,
        openliveRole: process.env.OPEBLIVE_ROLE || 2,
        openliveAccountantRole: 6,
    },
    defaultRole: {
        UI_TYPE_PERMISSION: "UI",
        API_TYPE_PERMISSION: "API",
        superAdmin: {
            id: process.env.SUPER_ADMIN_ROLE_ID || 1,
            name: process.env.SUPER_ADMIN_ROLE_NAME || "super_admin",
        },
        brandAdmin: {
            id: process.env.BRAND_ADMIN_ROLE_ID || 2,
            name: process.env.BRAND_ADMIN_ROLE_NAME || "brand_admin",
        },
        brandExecutive: {
            id: process.env.BRAND_EXECUTIVE_ROLE_ID || 3,
            name: process.env.BRAND_EXECUTIVE_ROLE_NAME || "brand_executive",
        },
        openlive: {
            id: process.env.OPENLIVE_ROLE_ID || 4,
            name: process.env.OPENLIVE_ROLE_NAME || "openlive",
        },
    },
    permissionGroupName: {
        openlive_staff_api: "admin",
        openlive_staff_ui: "openlive_staff_ui",
        brand_admin_ui: "brand_admin_ui",
        brand_executive_ui: "brand_executive_ui",
        normal_user_ui: "normal_user_ui",
    },
    apiPermissionName: {
        edit_campaign: "edit_campaign",
        create_campaign: "create_campaign",
        create_collection: "create_collection",
        reject_membership: "reject_membership",
        approve_membership: "approve_membership",
        reject_fan: "reject_fan",
        approve_fan: "approve_fan",
        reject_voucher: "reject_voucher",
        approve_voucher: "approve_voucher",
        invite_staff: "invite_staff",
        remove_staff: "remove_staff",
    },
    brandAdmin: {
        countUsersReportNew: process.env.COUNT_USERS_REPORT_NEW_INTERVAL || 900000,
    },
};
var API_HOST = config.api.host;
var GLOBAL_UPDATE_INTERVAL = config.global.globalUpdateInterval;
var SUB_URL = config.global.subUrl;
var CDN_URL = config.global.cdnUrl;
var ROLE_TYPE = config.roleType;
var DEFAULT_ROLE = config.defaultRole;
var PERMISSION_GROUP_NAME = config.permissionGroupName;
var API_PERMISSION_NAME = config.apiPermissionName;
var COUNT_USERS_REPORT_NEW_INTERVAL = config.brandAdmin.countUsersReportNew;
var DOWNLOAD_METAMASK_URL = config.global.downloadMetamaskUrl;
var OBRANDING_URL = config.global.obrandingURL;
var GOOGLE_RECAPTCHA_KEY = config.global.googleRecaptcharKey;
var VERSION = config.version;
export { API_HOST, GLOBAL_UPDATE_INTERVAL, SUB_URL, CDN_URL, ROLE_TYPE, DEFAULT_ROLE, PERMISSION_GROUP_NAME, API_PERMISSION_NAME, COUNT_USERS_REPORT_NEW_INTERVAL, DOWNLOAD_METAMASK_URL, OBRANDING_URL, GOOGLE_RECAPTCHA_KEY, VERSION };
export default config;
